import Address from "./Address";
import BaseCom from "./BaseCom";
import Card from "./Card";
import FormInput from "./FormInput";
import Icon from "./Icon";
import { withParamsAndNavigate } from "./WithParamsAndNavigate";

class Shipping extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            info: this.props.item ? this.props.item : {}
        };
        this.onChange = this.onChange.bind(this);
    }

    componentDidUpdate(prevProps) {
        if(this.props.item !== prevProps.item)
            this.setState({ info: this.props.item });
    }

    onChange(v) {
        if(this.props.onChange)
            this.props.onChange(this.state.info);
    }

    render() {
        return <div className="container-fluid">
            <div className="row">
                <div className="col-md">
                    <Card title="Shipping" headerButtons={this.props.onRemove ? <button type="button" className="btn btn-danger" onClick={this.props.onRemove}><Icon icon="faTrash" /></button> : null}>
                        <FormInput model={this.state.info} name="ship_name" label="Location Name" type="text" labelAsPlaceholder onChange={this.onChange} disabled={this.props.disabled} />
                    </Card>
                    <Address item={this.state.info} labelAsPlaceholder onChange={this.onChange} title="Shipping Address" disabled={this.props.disabled} />
                </div>
                <div className="col-md">
                    <Card title="Shipping Contact">
                        <FormInput model={this.state.info} name="phone" label="Phone" type="text" labelAsPlaceholder onChange={this.onChange} disabled={this.props.disabled} />
                        <FormInput model={this.state.info} name="fax" label="Fax" type="text" labelAsPlaceholder onChange={this.onChange} disabled={this.props.disabled} />
                    </Card>
                </div>
            </div>

        </div>;
    }
}

export default withParamsAndNavigate(Shipping);