import { Link } from "react-router-dom";
import Globals from "../shared/Globals";
import { qreq } from "../shared/qrequest";
import BaseCom from "./BaseCom";
import DataTable from "./DataTable";
import L from "./Lang";
import LoadingAlert from "./LoadingAlert";
import { NavMenuGlobal } from "./NavMenu";
import { withParamsAndNavigate } from "./WithParamsAndNavigate";

class EODList extends BaseCom {
    componentDidMount() {
        NavMenuGlobal.setTitle('EOD');
        qreq.get('/api/visit/eod/list', j => {

            if(j.list) {
                if(j.list.length > 0 && Globals.toISOLocalDateOnlyString(j.list[0].trans_date_in) === Globals.toISOLocalDateOnlyString(this.props.dt)) {
                    j.list[0].current = true;
                }
                else {
                    j.list.splice(0,0, {
                        trans_date_in: 'Current'
                    });    
                }
                this.setState({ list: j.list });
            }
            else
                this.alert(j.errorMessage);
        }, this.unkownErrorCallback);
    }

    componentWillUnmount() {
        NavMenuGlobal.clearTitle();
    }

    render() {
        if(!this.state.list)
            return <LoadingAlert />
        return <div className="container-fluid">
            <DataTable columns={[
                {
                    name: 'date', title: <L>Date</L>, valueFunc: row => {
                        if (row.trans_date_in === 'Current')
                            return <Link to="/console/eod/current">Current</Link>
                        else
                            return <Link to={'/console/eod/' + Globals.toISOLocalDateOnlyString(row.trans_date_in)}>{Globals.toMDYLocalDateString(row.trans_date_in)} {row.current && '(Today)'}</Link>
                    }
                }
            ]} data={this.state.list} />
        </div>;
    }
}

export default withParamsAndNavigate(EODList);