import { Modal } from "react-bootstrap";
import BaseCom from "./BaseCom";
import LoadingAlert from "./LoadingAlert";
import { withParamsAndNavigate } from "./WithParamsAndNavigate";
import L from "./Lang";
import { qreq } from "../shared/qrequest";
import { Link } from "react-router-dom";
import Icon from "./Icon";
import { NavMenuGlobal } from "./NavMenu";
import GestureElement from "./GestureElement";
import { Pop } from "./PopModal";
import Card from "./Card";
import FormLabel from "./FormLabel";
import Globals from "../shared/Globals";
import Auth from "../shared/Auth";
import Alert from "./Alert";
import TaxUpdateModal from "./TaxUpdateModal";
import { PrintGlobal } from "./PrintView";

class VisitOrder extends BaseCom {
    constructor(props) {
        super(props);
        this.load = this.load.bind(this);
        this.delete = this.delete.bind(this);
        this.clearActive = this.clearActive.bind(this);
        this.newOrder = this.newOrder.bind(this);
        this.checkTax = this.checkTax.bind(this);
        this.checkAR = this.checkAR.bind(this);
        this.gps = this.gps.bind(this);
        this.mine = this.mine.bind(this);
    }

    componentDidMount() {
        Auth.getLogin(u => {
            this.setState({ user: u});
        });
        qreq.get('/api/visit/item?id=' + this.props.params.id, j => {
            if (j.item) {
                this.setState({ visit: j.item });
                this.load(j.item.id);
                this.checkTax(j.item.customerID, j.item.customerShippingID);
                this.checkAR(j.item.customerID);
            }
            else
                this.alert(j.errorMessage);
        }, this.unkownErrorCallback);
        NavMenuGlobal.setPath('Visits,/console/visit;Order');
        Auth.getLogin(u => {
            this.setState({ user: u });
        });
    }

    componentWillUnmount() {
        NavMenuGlobal.clearTitle();
    }

    load(visitID) {
        qreq.get('/api/order/item/list?routePlanID=' + visitID, j => {
            if (j.list)
                this.setState({ list: j.list });
            else
                this.alert(j.errorMessage);
        }, this.unkownErrorCallback);
    }

    newOrder(direct) {
        if (direct)
            this.props.navigate('/console/visit/' + this.state.visit.id + '/order/new/direct');
        else
            this.props.navigate('/console/visit/' + this.state.visit.id + '/order/new/pre');
     }

    delete(item) {
        Pop.showConfirm(<><L>Are you sure you want to delete:</L> {item.ord_id}-{item.ord_seq}</>, 'Delete', () => {
            qreq.post('/api/order/delete', { ...item }, j => {
                if (j.errorCode)
                    this.alert(j.errorMessage);
                else
                    this.success('Order deleted.');
                this.load(this.state.visit.id);
            }, this.unkownErrorCallback);
        });
    }

    clearActive() {
        if (!this.state.list)
            return;
        var list = [...this.state.list];
        list.forEach(i => {
            i.active = false;
        });
        this.setState({ list: list });
    }

    checkTax(custID, shipID) {
        qreq.get('/api/customer/tax/check?custID=' + custID + '&shipID=' + shipID, j => {
            if (j.errorCode === 6)
                this.setState({ taxInfo: { errorMessage: 'Tax information not found.' } });
            else if (j.item)
                this.setState({ taxInfo: { ...j.item, errorMessage: j.item.isExpired ? 'Tax information is expired.' : null } });
            else
                this.alert(j.errorMessage);
        }, this.unkownErrorCallback);
        this.setState({ info: { ...this.state.info } })
    }

    checkAR(custID) {
        this.setState({ arMessage: null });
        qreq.get('/api/ar/list?custID=' + custID, j => {
            if(j.list) {
                if(j.list.length > 0)
                    this.setState({ arMessage: <L>Customer has pending balances.</L> });
                else if(j.errorCode)
                    this.alert(j.errorMessage);
            }
        }, this.unkownErrorCallback);
    }

    gps() {
        qreq.get('/api/customer/loc?custID=' + this.state.visit.customerID + '&shipID=' + this.state.visit.customerShippingID, j => {
            if(j.errorCode === 9) {
                Pop.showConfirm(<><L>No location found for this customer, would you like to set one now?</L> <strong><L>Only do this if you are currently at the customer location!</L></strong></>, 'Location', () => {
                    if(!navigator.geolocation) {
                        this.alert('Geolocation is not supported by your device.');
                        return;
                    }
                    navigator.geolocation.getCurrentPosition(position => {
                        qreq.post('/api/customer/loc/update', {...this.state.visit, lat: position.coords.latitude, lng: position.coords.longitude }, j => {
                            if(j.errorCode)
                                this.alert(j.errorMessage);
                            else
                                this.success('Successfully updated GPS coordinates.');
                        }, this.unkownErrorCallback);
                    }, (error) => {
                        switch (error.code) {
                            case error.PERMISSION_DENIED:
                                this.alert('User denied the request for Geolocation.');
                                break;
                            case error.POSITION_UNAVAILABLE:
                                this.alert('Location information is unavailable.');
                                break;
                            case error.TIMEOUT:
                                this.alert('The request to get user location timed out.');
                                break;
                            default:
                                this.alert('Unkown error ocurred.');
                        }
                    });
                });
            }
            else if(j.item) {
                if(Globals.iOS())
                    window.open('https://maps.apple.com/?q=' + j.item.lat + ',' + j.item.lng);
                else
                    window.open('https://www.google.com/maps/search/' + j.item.lat + ',' + j.item.lng);
            }
            else
                this.alert(j.errorMessage);
        }, this.unkownErrorCallback);
        
    }

    mine() {
        if(this.state.visit?.user_num === this.state.user?.user1?.user_num)
            return true;
        return false;
    }

    render() {
        return <div className="container-fluid" onClick={() => this.clearActive()}>
            <Modal show={this.state.printItem} onHide={() => this.setState({ printItem: null })}>
                <Modal.Header>
                    Purchase Order
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col text-center">
                            <Icon icon="faFilePdf" />
                        </div>
                        <div className="col">
                            <Icon icon="faEnvelope" />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {this.state.visit ? <>
                <TaxUpdateModal custID={this.state.visit.customerID} shipID={this.state.visit.customerShippingID} show={this.state.showTaxUpdateModal} onHide={() => { this.setState({ showTaxUpdateModal: false }); this.checkTax(this.state.visit.customerID, this.state.visit.customerShippingID); }} />
                <Card title="Visit">
                    <FormLabel value={this.state.visit.customerName} label="Customer" />
                    <FormLabel value={this.state.visit.customerShippingName} label="Shipping" />
                    {this.state.arMessage ? <Alert type="danger" onClick={() => this.props.navigate('/console/visit/' + this.state.visit.id + '/statement/' + this.state.visit.customerID)}>{this.state.arMessage}<br /><strong><L>Click here to view.</L></strong></Alert> : null}
                    {this.state.taxInfo && this.state.taxInfo.errorMessage ? <Alert type="warning" onClick={() => this.setState({ showTaxUpdateModal: true })}>{this.state.taxInfo.errorMessage}<br /><strong><L>Click here to update tax information.</L></strong></Alert> : null}
                    <div className="d-flex gap-1 my-3">
                        <button type="button" className="btn btn-secondary btn-lg" onClick={this.gps}><Icon icon="faLocation" /> <L>GPS</L> <span className="d-none d-md-inline-block"><L>Location</L></span></button>
                        {this.state.arMessage ? <Link type="button" className="btn btn-danger btn-lg" to={'/console/visit/' + this.state.visit.id + '/statement/' + this.state.visit.customerID}><Icon icon="faCashRegister" /> <span className="d-none d-md-inline-block"><L>Account Receivable</L></span><span className="d-inline-block d-md-none"><L>A/R</L></span></Link> : null}
                        <Link type="button" className="btn btn-secondary btn-lg" to={'/console/visit/' + this.state.visit.id + '/ytd/' + this.state.visit.customerID}><Icon icon="faFileInvoice" /> <span className="d-none d-md-inline-block"><L>Year to Date</L></span><span className="d-inline-block d-md-none"><L>YTD</L></span></Link>
                    </div>
                </Card>
                <div className="app-actions">
                    <button onClick={() => this.newOrder(false)} type="button" className="btn btn-primary btn-lg"><Icon icon="faShop" /></button>
                </div>
                <Card title="Purchase Orders">
                    {this.state.list ? this.state.list.length === 0 ? <><Alert><L>There are no orders.</L></Alert></>
                        : <div className="list-group">
                            {this.state.list.map(i => <GestureElement key={i.id} className="list-group-item d-flex align-items-center"
                                onSwipeRight={() => { i.active = true; this.setState({ list: [...this.state.list] }); }}
                                onSwipeLeft={() => { i.active = false; this.setState({ list: [...this.state.list] }); }}
                            >
                                <Link to={i.saleType === 2 ? '/console/visit/' + this.state.visit.id + '/order/pre/' + i.id : '/console/visit/' + this.state.visit.id + '/order/pre/' + i.id} className="d-block flex-fill">
                                    <div>{i.orderIdent}</div>
                                    <div className="text-muted small">Deliver On: {Globals.toDateDeltaDisplay(i.deliver_on)}</div>
                                    {this.state.user?.isSupervisor && <div className="small text-super"><L>Salesman:</L> {i.createdByUserName}</div>}
                                </Link>
                                <div className={i.active ? 'd-flex gap-1' : 'd-none d-md-flex gap-1'}>
                                    <button type="button" className="btn btn-lg btn-primary" onClick={() => PrintGlobal.show(Globals.serverUrl + '/api/receipt/sale/html/' + i.saleType + '/' + (i.saleType === 2 ? i.invoiceID : i.id) + '/' + i.user_num + '-' + i.ord_id + '-' + i.ord_seq + '.html')}><Icon icon="faReceipt" /></button>
                                    <button type="button" className="btn btn-primary btn-lg" onClick={() => window.open(Globals.serverUrl + '/api/report/purchase-order/pdf/' + i.saleType + '/' + (i.saleType === 2 ? i.invoiceID : i.id) + '/' + i.user_num + '-' + i.ord_id + '-' + i.ord_seq + '.pdf')}><Icon icon="faFilePdf" /></button>
                                    <button type="button" className="btn btn-danger btn-lg" onClick={() => this.delete(i)}><Icon icon="faTrash" /></button>
                                </div>
                            </GestureElement>)}
                        </div> : <LoadingAlert />}
                    <div className="my-3">
                        <button onClick={() => this.newOrder(false)} type="button" className="btn btn-primary btn-lg"><Icon icon="faShop" /> <L>New Order</L></button>
                    </div>
                </Card>
            </> : <LoadingAlert />}
        </div>;
    }
}

export default withParamsAndNavigate(VisitOrder);