import React from "react";
import { qreq } from "../shared/qrequest";
import BaseCom from "./BaseCom";
import Icon from "./Icon";
import L from "./Lang";
import { NavMenuGlobal } from "./NavMenu";
import { withParamsAndNavigate } from "./WithParamsAndNavigate";
import SignatureCanvas from "react-signature-canvas";
import Globals from "../shared/Globals";
import Card from "./Card";
import FormInput from "./FormInput";
import { Modal } from "react-bootstrap";



class VisitSign extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            termList: []
        };
        this.sigCanvasRef = React.createRef();
        this.loadOrder = this.loadOrder.bind(this);
        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
        NavMenuGlobal.setPath('Visits,/console/visit;Order');
        qreq.get('/api/visit/item?id=' + this.props.params.id, j => {
            if (j.item) {
                this.setState({ visit: j.item });
                this.loadOrder();
            }
            else
                this.alert(j.errorMessage);
        }, this.unkownErrorCallback);
    }

    componentWillUnmount() {
        NavMenuGlobal.clearTitle();
    }

    loadOrder() {
        qreq.get('/api/order/item?id=' + this.props.params.orderID + '&includeTotals=true', j => {
            if (j.item) {
                if(this.props.direct)
                    j.item.transType = 'DI';
                this.setState({ order: j.item, items: j.item.items });
                this.loadTerms(j.item.cust_num);
            }
            else {
                this.alert(j.errorMessage);
                this.goBack();
            }
        }, () => {
            this.unkownErrorCallback();
            this.goBack();
        });
    }

    loadTerms(custNum) {
        qreq.get('/api/customer/term/list' , j => {
            if (j.list) {
                this.setState({ termList: j.list });
                this.loadCustomer(custNum);
            }
            else
                this.alert(j.errorMessage);
        }, this.unkownErrorCallback);
    }

    loadCustomer(custNum) {
        qreq.get('/api/customer/item?custNum=' + custNum, j => {
            if(j.item) {
                this.setState({ customer: j.item });
                if(j.item.term_code) {
                    var find = this.state.termList.find(t => t.term_code === j.item.term_code);
                    if(find)
                        this.setState({ order: { ...this.state.order, termID: find.id } });
                }
            }
            else
                this.alert(j.errorMessage);
        }, this.unkownErrorCallback);
    }

    submit(e, forceType) {
        e.preventDefault();
        if (!this.state.visit || !this.state.order) {
            this.alert('Visit or Order not loaded.');
            return;
        }
        if (!this.sigCanvasRef.current.isEmpty()) {
            var img = this.sigCanvasRef.current.getTrimmedCanvas().toDataURL('image/png');
            var p = img.split(',');
            if (p.length > 1) {
                var directSale = this.props.direct;
                
                this.setState({submitting: true})
                qreq.post('/api/order/sign', { orderID: this.state.order.id, orderBy: this.state.order.orderBy, poNum: this.state.order.poNum, termID: this.state.order.termID, base64Content: p[1], isDirectSale: directSale, message: this.state.order.message }, j => {
                    if (j.errorCode === 0 && j.item)
                        this.props.navigate('/console/visit/' + this.state.visit.id + '/order/done/' + (this.props.pre ? 'pre' : 'direct') + '/' + j.item);
                    else
                        this.alert(j.errorMessage);
                    this.setState({submitting: false});
                }, () => {
                    this.unkownErrorCallback();
                    this.setState({submitting: false});
                });
                return;
            }
        }
        this.alert('Please sign first.');
    }

    render() {
        return <div className="container-fluid">
            <form onSubmit={this.submit}>
                <table className="table table-bordered">
                    <tbody>
                        <tr><td><L>Order Type</L></td><td>{this.props.pre ? 'Pre-Order' : 'Direct Sale'}</td></tr>
                        <tr><td><L>Customer</L></td><td>{this.state.visit?.customerName}</td></tr>
                        <tr><td><L>Ship Name</L></td><td>{this.state.visit?.customerShippingName}</td></tr>
                        <tr><td><L>Order #</L></td><td>{this.state.order?.orderIdent}</td></tr>
                        <tr><td><L>Total # of Items</L></td><td>{(() => {
                            if (this.state.items) {
                                var qty = 0;
                                this.state.items.forEach(i => {
                                    qty += i.quantity;
                                });
                                return qty;
                            }
                            return 0;
                        })()}</td></tr>
                        <tr><td><L>Sub-Total</L></td><td>{Globals.formatPrice(this.state.order?.subTotal)}</td></tr>
                        <tr><td><L>Sales Tax</L></td><td>{Globals.formatPrice(this.state.order?.salesTax)}</td></tr>
                        <tr><td><L>Total</L></td><td>{Globals.formatPrice(this.state.order?.total)}</td></tr>
                    </tbody>
                </table>
                

                {this.state.order ? <Card title="Order">
                    <FormInput model={this.state.order} name="orderBy" label="Order By" type="text" onChange={this.onChange} required placeholder="Person who is signing" />
                    <FormInput model={this.state.order} name="poNum" label="PO Num" type="text" onChange={this.onChange} required={this.state.customer?.isPORequired} placeholder={this.state.customer?.isPORequired ? 'PO number here' : null} />
                    <FormInput model={this.state.order} name="termID" label="Term" type="select" onChange={this.onChange} options={this.state.termList} />
                    {this.props.direct && <FormInput model={this.state.order} name="transType" label="Transaction Type" type="select" onChange={this.onChange} required options={[
                        { id: 'DI', name: 'Debit Invoice' },
                        { id: 'CM', name: 'Credit Memo' }
                    ]} />}
                    <FormInput model={this.state.order} name="message" label="Message" type="textarea" onChange={this.onChange} />
                </Card> : null}

                <div className="d-grid align-items-center justify-content-center">
                    <label className="form-label"><L>Sign below</L></label>
                    <SignatureCanvas ref={this.sigCanvasRef} canvasProps={{ width: 400, height: 200, className: 'border', style: { maxWidth: '100%' } }} />
                    <button type="button" className="btn btn-secondary" onClick={() => this.sigCanvasRef.current.clear()}><L>Clear Signature</L></button>
                </div>
                <div className="d-flex gap-1 app-form-actions">
                    <div>
                        <button type="button" className="btn btn-secondary btn-lg" onClick={this.goBack}><Icon icon="faArrowLeft" /> <L>Back</L></button>
                    </div>
                    <div className="flex-fill text-end">
                        <button type="submit" className="btn btn-primary btn-lg" disabled={!this.state.visit || !this.state.order || this.state.submitting}><Icon icon="faSignature" /> <L>Confirm & Sign</L></button>
                    </div>
                </div>
            </form>
        </div>;
    }
}

export default withParamsAndNavigate(VisitSign);