import { qreq } from "../shared/qrequest";
import Address from "./Address";
import Alert from "./Alert";
import BaseCom from "./BaseCom";
import Card from "./Card";
import FormInput from "./FormInput";
import Icon from "./Icon";
import L from "./Lang";
import LoadingAlert from "./LoadingAlert";
import { NavMenuGlobal } from "./NavMenu";
import Shipping from "./Shipping";
import { withParamsAndNavigate } from "./WithParamsAndNavigate";

class Customer extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            info: {},
            shippings: [],
            existing: false,
            new: true,
            searchCust: true
        };
        this.load = this.load.bind(this);
        this.loadShipping = this.loadShipping.bind(this);
        this.selectCustomer = this.selectCustomer.bind(this);
        this.newCustomer = this.newCustomer.bind(this);
        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
        NavMenuGlobal.setPath('Customer;' + (this.props.params.id ? 'Edit' : 'New'));
        this.fillList('/api/customer/group/list', 'groupList');
        this.fillList('/api/customer/sa/list', 'saList');
        this.fillList('/api/customer/term/list', 'termList');
        this.load();
    }

    componentWillUnmount() {
        NavMenuGlobal.clearTitle();
    }

    load() {
        if (this.props.params.id) {
            qreq.get('/api/customer/item?id=' + this.props.params.id, j => {
                if (j.item) {
                    this.setState({ info: j.item, new: false, searchCust: false, existing: true });
                    this.loadShipping(j.item.id);
                }
                else
                    this.alert(j.errorMessage);
            }, this.unkownErrorCallback);
        }
    }

    loadShipping(custID) {
        this.fillList('/api/customer/shipping/list?custID=' + custID, 'shippings');
    }

    selectCustomer(v) {
        if (v)
            this.fillItem('/api/customer/item?id=' + v.id, 'info');
        else
            this.setState({ info: {} });
        this.setState({ new: v ? false : true, searchCust: v ? false : true })
    }

    newCustomer(v) {
        this.setState({ new: true, searchCust: false, info: { cust_name: v, customerID: null } })
    }
    
    submit(e) {
        e?.preventDefault();
        qreq.post('/api/customer/save', {...this.state.info, shippings: this.state.shippings }, j => {
            if(j.errorCode)
                this.alert(j.errorMessage);
            else {
                this.success('Customer saved successfully!');
                this.goBack();
            }
        }, this.unkownErrorCallback);
    }

    render() {
        return <div className="container-fluid">
            <form onSubmit={this.submit}>
                <div className="row">
                    <div className="col-md">
                        <Card title="Customer">
                            {this.state.existing ? <input type="text" value={this.state.info?.cust_name} disabled className="form-control" /> : 
                                <div><FormInput model={this.state.info} name="cust_name" label="Customer Name" type="text" labelAsPlaceholder onChange={v => this.setState({ searchCust: v ? false : true, new: v ? true : false })} transformUpperCase required /></div>}
                        </Card>
                        <Address item={this.state.info} labelAsPlaceholder disabled={this.state.searchCust} />
                        <Card title="Contact Info">
                            <FormInput model={this.state.info} name="phone" label="Phone" type="text" labelAsPlaceholder disabled={this.state.searchCust} />
                            <FormInput model={this.state.info} name="fax" label="Fax" type="text" labelAsPlaceholder disabled={this.state.searchCust} />
                        </Card>
                    </div>
                    <div className="col-md">
                        <Card title="Group">
                            {this.state.groupList ? <FormInput model={this.state.info} name="cust_group" label="Group" type="select" keyName="cust_group" options={this.state.groupList} labelAsPlaceholder disabled={this.state.searchCust} /> : <LoadingAlert />}
                            {this.state.saList ? <FormInput model={this.state.info} name="sa_cust" label="S/A" type="select" keyName="sa_cust" options={this.state.saList} labelAsPlaceholder disabled={this.state.searchCust} /> : <LoadingAlert />}
                            {this.state.termList ? <FormInput model={this.state.info} name="term_code" label="Term" type="select" keyName="term_code" options={this.state.termList} labelAsPlaceholder disabled={this.state.searchCust} /> : <LoadingAlert />}
                        </Card>
                    </div>
                    <div className="col-md">
                        <Card title="Credit">
                            <FormInput model={this.state.info} name="credit_limit" label="Credit Limit" type="number" step="0.01" noHelpers placeholder="0.00" disabled={this.state.searchCust} />
                            <FormInput model={this.state.info} name="ar_current" label="AR Current" type="number" step="0.01" noHelpers placeholder="0.00" disabled={this.state.searchCust} />
                            <FormInput model={this.state.info} name="ar_30_days" label="AR 30 Days" type="number" step="0.01" noHelpers placeholder="0.00" disabled={this.state.searchCust} />
                            <FormInput model={this.state.info} name="ar_60_days" label="AR 60 Days" type="number" step="0.01" noHelpers placeholder="0.00" disabled={this.state.searchCust} />
                            <FormInput model={this.state.info} name="ar_90_days" label="AR 90 Days" type="number" step="0.01" noHelpers placeholder="0.00" disabled={this.state.searchCust} />
                            <FormInput model={this.state.info} name="ar_120_days" label="AR 120 Days" type="number" step="0.01" noHelpers placeholder="0.00" disabled={this.state.searchCust} />
                        </Card>
                    </div>
                </div>
                {this.state.shippings.map(i => <Shipping key={i.id} item={i} onRemove={info => this.setState({ shippings: [...this.state.shippings].splice(this.state.shippings.indexOf(i), 1, info) })} disabled={this.state.searchCust} onRemove={() => this.remList('shippings', i)} />)}
                <div className="m-3">
                    <button type="button" className="btn btn-secondary btn-lg" onClick={() => this.pushList('shippings', {})} disabled={this.state.searchCust}><Icon icon="faShip" /> Add Shipping Location</button>
                </div>
                <div className="my-3 d-flex gap-1 app-form-actions">
                    <div>
                        <button type="button" className="btn btn-secondary btn-lg" onClick={this.goBack}><Icon icon="faArrowLeft" /> <L>Back</L></button>
                    </div>
                    <div className="flex-fill text-end">
                        <button type="submit" className="btn btn-primary btn-lg"><Icon icon="faSave" /> <L>Save</L></button>
                    </div>
                </div>
            </form>
        </div>;
    }
}

export default withParamsAndNavigate(Customer);