import React, { Component } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './components/Login';
import { PopModal } from './components/PopModal';
import { ToastContainer } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import Logout from './components/Logout';
import ConsoleLayout from './components/ConsoleLayout';
import Dashboard from './components/Dashboard';
import ForgotPassword from './components/ForgotPassword';
import SetPassword from './components/SetPassword';
import E404 from './components/E404';
import Visit from './components/Visit';
import VisitEdit from './components/VisitEdit';
import VisitStart from './components/VisitStart';
import VisitOrder from './components/VisitOrder';
import VisitOrderEdit from './components/VisitOrderEdit';
import VisitOrderSelect from './components/VisitOrderSelect';
import Customer from './components/Customer';
import Statement from './components/Statement';
import YTD from './components/YTD';
import { ImageViewCom } from './components/ImageView';
import Globals from './shared/Globals';
import { loadLang, pullCurrentLang } from './shared/GLang';
import StatementView from './components/StatementView';
import Menu from './components/Menu';
import OrderSign from './components/OrderSign';
import OrderDone from './components/OrderDone';
import EOD from './components/EOD';
import Promo from './components/Promo';
import Collection from './components/Collection';
import Catalog from './components/Catalog';
import StatementList from './components/StatementList';
import SaleQuote from './components/SaleQuote';
import CustomerList from './components/CustomerList';
import EODList from './components/EODList';
import InstancePrompt from './components/InstancePrompt';
import { PrintView } from './components/PrintView';
import { BarcodeScannerCom } from './components/BarcodeScanner';

export const AppGlobal = {
    setLang() {}
};

export default class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: 'es',
            serverUrl: Globals.serverUrl
        };
        this.setLang = this.setLang.bind(this);
        AppGlobal.setLang = this.setLang;
    }

    componentDidMount() {
        this.init();
    }

    init = (serverUrl) => {
        if(serverUrl) {
            Globals.serverUrl = serverUrl;
            this.setState({ serverUrl: serverUrl });
        }
        loadLang(() => {
            pullCurrentLang(lang => {
                this.setState({ lang: lang });
            });            
        });
    }

    setLang(lang) {
        Globals.setLang(lang);
        this.setState({ lang: lang });
    }

    render() {
        return (<>
            <PopModal />
            <InstancePrompt show={Globals.serverUrl === '__APP__'} onComplete={this.init} />
            <ImageViewCom />
            <PrintView />
            <BarcodeScannerCom />
            <ToastContainer position="top-center"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light" />
            <BrowserRouter lang={this.state.lang}>
                <Routes>
                    <Route index element={<Login serverUrl={this.state.serverUrl} />} />
                    <Route path="/login" element={<Login serverUrl={this.state.serverUrl} />} />
                    <Route path="/logout" element={<Logout />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/reset-password/:code" element={<SetPassword />} />
                    <Route path="/console" element={<ConsoleLayout lang={this.state.lang} />}>
                        <Route index element={<Dashboard lang={this.state.lang} />} />
                        <Route path="menu" element={<Menu lang={this.state.lang} />} />
                        <Route path="dashboard" element={<Dashboard lang={this.state.lang} />} />
                        <Route path="visit" element={<Visit />} />
                        <Route path="visit/select" element={<VisitOrderSelect />} />
                        <Route path="visit/new" element={<VisitEdit />} />
                        <Route path="visit/start" element={<VisitStart />} />
                        <Route path="visit/:id/start" element={<VisitStart />} />
                        <Route path="visit/:id/order" element={<VisitOrder />} />
                        <Route path="visit/:id/order/sign/direct/:orderID" element={<OrderSign direct />} />
                        <Route path="visit/:id/order/sign/pre/:orderID" element={<OrderSign pre />} />
                        <Route path="visit/:id/order/done/direct/:orderID" element={<OrderDone direct />} />
                        <Route path="visit/:id/order/done/pre/:orderID" element={<OrderDone pre />} />
                        <Route path="visit/:visitID/statement/:custID/:id" element={<StatementView />} />
                        <Route path="visit/:visitID/statement/:id" element={<Statement />} />
                        <Route path="visit/:visitID/ytd/:id" element={<YTD />} />
                        <Route path="visit/:id/order/new/direct" element={<VisitOrderEdit direct />} />
                        <Route path="visit/:id/order/new/pre" element={<VisitOrderEdit pre />} />
                        <Route path="visit/:id/order/direct/:orderID" element={<VisitOrderEdit direct />} />
                        <Route path="visit/:id/order/pre/:orderID" element={<VisitOrderEdit pre />} />
                        <Route path="customer" element={<CustomerList />} />
                        <Route path="customer/new" element={<Customer />} />
                        <Route path="customer/:id" element={<Customer />} />
                        <Route path="customer/:id/statement" element={<Statement />} />
                        <Route path="statement" element={<StatementList />} />
                        <Route path="statement/:id" element={<Statement />} />
                        <Route path="statement/:custID/:id" element={<StatementView />} />
                        <Route path="eod" element={<EODList />} />
                        <Route path="eod/current" element={<EOD />} />
                        <Route path="eod/:dt" element={<EOD />} />
                        <Route path="sale/quote/summary" element={<SaleQuote />} />
                        <Route path="promo" element={<Promo />} />
                        <Route path="collection" element={<Collection />} />
                        <Route path="catalog" element={<Catalog />} />
                        <Route path="*" element={<E404 fluid />} />
                    </Route>
                    <Route path="*" element={<E404 />} />
                </Routes>
            </BrowserRouter>
            </>
        );
    }
}
