import { Link } from "react-router-dom";
import Globals from "../shared/Globals";
import { qreq } from "../shared/qrequest";
import BaseCom from "./BaseCom";
import Card from "./Card";
import FormInput from "./FormInput";
import FormLabel from "./FormLabel";
import Icon from "./Icon";
import L from "./Lang";
import { NavMenuGlobal } from "./NavMenu";
import { withParamsAndNavigate } from "./WithParamsAndNavigate";
import ItemCatalog from "./ItemCatalog";
import DataTable from "./DataTable";
import ItemView from "./ItemView";
import Auth from "../shared/Auth";
import GestureElement from "./GestureElement";
import Tick from "./Tick";
import { Pop } from "./PopModal";
import { Modal } from "react-bootstrap";

class OrderTypeModal extends BaseCom {
    render() {
        return <Modal show={this.props.show} onHide={this.props.onHide}>
            <Modal.Header closeButton>
                <Modal.Title><L>Order Type</L></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-grid gap-2">
                    <button type="button" className="btn btn-primary" onClick={() => this.props.onSelect('pre')}><L>Pre-Order</L></button>
                    <button type="button" className="btn btn-primary" onClick={() => this.props.onSelect('direct')}><L>Direct Sale</L></button>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-secondary" onClick={this.props.onHide}><L>Cancel</L></button>
            </Modal.Footer>
        </Modal>;
    }
}

class VisitOrderEdit extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            info: {
                ord_type: '1'
            },
            ships: [],
            items: []
        };
        this.state._info = this.state.info;
        this.state._items = this.state.items;
        this.submit = this.submit.bind(this);
        this.loadOrder = this.loadOrder.bind(this);
        this.addItem = this.addItem.bind(this);
        this.editItem = this.editItem.bind(this);
        this.tick = this.tick.bind(this);
        this.onChange = this.onChange.bind(this);
        this.sign = this.sign.bind(this);
    }

    componentDidMount() {
        Auth.getLogin(u => {
            this.setState({ user: u });
            if (u) {
                this.setState({ loaded: true });
           }
            else {
                this.alert('Not logged in.');
                this.goBack();
            }
        });

        NavMenuGlobal.setPath('Visits,/console/visit;Order,/console/visit/' + this.props.params.id + '/order;Create');
        qreq.get('/api/visit/item?id=' + this.props.params.id, j => {
            if (j.item)
                this.setState({ visit: j.item });
            else
                this.alert(j.errorMessage);
        }, this.unkownErrorCallback);

        if (this.props.params.orderID) {
            this.loadOrder();
        }
        else {
            var dt = new Date();
            var info = {...this.state.info, deliver_on: Globals.toISOLocalDateString(new Date(dt.setDate(dt.getDate() + 2)))};
            this.setState({info: info, _info: info});
        }
    }

    componentWillUnmount() {
        NavMenuGlobal.clearTitle();
        if (!this.state.info?.isSigned && (this.state._info !== this.state.info || this.state._items !== this.state.items)) {
            this.submit();
        }
    }

    tick() {
        if (this.state._info !== this.state.info || this.state._items !== this.state.items) {
            this.submit();
            this.setState({ _items: this.state.items, _info: this.state.info });
        }
    }

    loadOrder(callback) {
        qreq.get('/api/order/item?id=' + this.props.params.orderID + '&saleType=' + (this.props.pre ? '1' : '2'), j => {
            if (j.item)
                this.setState({ info: j.item, _info: j.item, items: j.item.items, _items: j.item.items });
            else
                this.alert(j.errorMessage);
            if (callback)
                callback();
        }, this.unkownErrorCallback);
    }

    addItem(item, info) {
        //this.setState({ showItemCatalog: false });
        if (info.quantity !== 0) {
            var items = [...this.state.items];
            items.push({ ...item, ...info });
            this.setState({ items: items });
        }
    }

    editItem(item, info) {
        this.setState({ selectedItem: null });
        var items = [...this.state.items];
        var find = items.find(a => a.item_num === item.item_num && a.quantity === info.originalQuantity);
        if (find) {
            if (info.quantity === 0) {
                items.splice(items.indexOf(find), 1);
            }
            else {
                find.quantity = info.quantity;
                find.message = info.message;
                find.orderUOM = info.orderUOM;
                find.price = info.price;
                find.discount = info.discount;
                find.customPrice = Number(info.customPrice);
                find.isCustomPrice = info.isCustomPrice;
            }
        }
        this.setState({ items: items });
    }

    deleteItem(item) {
        var items = [...this.state.items];
        items.splice(items.indexOf(item), 1);
        this.setState({ items: items });
    }

    onChange() {
        this.setState({ info: { ...this.state.info } })
    }

    submit(e, callback, items) {
        if (e) e.preventDefault();
        if(this.state.saving && !this.state.id) {
            setTimeout(() => this.submit(null, callback, items), 1000);
            return;
        }
        if(!items)
            items = this.state.items;
        var info = { ...this.state.info };
        if (info.isBlocked)
            return;
        if (info.isSigned && !this.state.overrideSign) {
            if (this.state.showPopup)
                return;
            this.setState({ showPopup: true });
            Pop.showConfirm(<L>Order has been signed. Any further modifications will clear the signature. Are you sure you want to continue?</L>, 'Clear Signature', () => {
                this.setState({ overrideSign: true, showPopup: false });
                this.submit(null, callback);
            }, () => {
                this.loadOrder(() => {
                    this.setState({ showPopup: false });
                });
            });
            return;
        }
        info.routePlanID = this.state.visit.id;
        info.items = [...items];
        info.items.forEach(i => {
            if(!i.customPrice) i.customPrice = -1;
        });
        if (!info.deliver_on)
            info.deliver_on = Globals.toISODateString(new Date());
        // Sale Type
        info.saleType = this.props.pre ? 1 : 2;
        this.saving = true;
        this.setState({ saving: true });
        qreq.post('/api/order/save', info, j => {
            if (j.errorCode)
                this.alert(j.errorMessage);
            else if (j.item) {
                //this.success(<L>Order has been saved.</L>);
                //this.goBack();
                info = { ...info, id: j.item.id };
                this.setState({ info: info, _info: info });
                if (!this.props.params.orderID)
                    this.props.navigate('/console/visit/' + this.state.visit.id + '/order/' + (this.props.pre ? 'pre' : 'direct') + '/' + j.item.id, { replace: true });
                if (callback)
                    callback();
            }
            this.setState({ saving: false });
            this.saving = false;
        }, () => {
            this.unkownErrorCallback();
            this.setState({ saving: false });
            this.saving = false;
        });
        return true;
    }

    sign(e, orderType) {
        if (e) e.preventDefault();
        if(!orderType && this.state.user.user3.hasPreSale && this.state.user.user3.hasDirectSale) {
            this.setState({ showOrderTypeModal: true });
            return;
        }
        if(!orderType && this.state.user.user3.hasDirectSale) {
            orderType = 'direct';
        }
        this.submit(null, () => {
            this.props.navigate('/console/visit/' + this.props.params.id + '/order/sign/' + (orderType ?? 'pre') + '/' + this.state.info.id);
        });
    }

    render() {
        if (!this.state.loaded)
            return null;

        if (this.state.selectedItem && this.state.visit) {
            NavMenuGlobal.setPath('Catalog');
            return <ItemView onEditItem={this.editItem} customerID={this.state.visit.customerID} item={this.state.selectedItem} onCancel={() => this.setState({ selectedItem: null })} />
        }
        else if (this.state.showItemCatalog && this.state.visit) {
            NavMenuGlobal.setPath('Catalog');
            return <ItemCatalog onAddItem={this.addItem} customerID={this.state.visit.customerID} onCancel={() => this.setState({ showItemCatalog: false })} categoryFilter={this.state.categoryFilter} onFilterChange={filter => this.setState({ categoryFilter: filter })} selectedItems={this.state.items} />
        }
        else
            NavMenuGlobal.setPath('Visits,/console/visit;Order,/console/visit/' + this.props.params.id + '/order;Create');

        var columns = [
            {
                name: 'desc_1', title: 'Description', onClick: row => {
                    if (!this.state.info?.isBlocked)
                        this.setState({ selectedItem: row });
                }, style: { width: '60%' }
            },
            {
                name: 'quantity', title: 'Quantity', onClick: row => {
                    if (!this.state.info?.isBlocked)
                        this.setState({ selectedItem: row });
                }, valueFunc: row => {
                    return <>
                        <div># {row.item_num}</div>
                        <span className="fw-bold">{row.quantity}</span> x <span className="fw-bold">{Globals.formatPrice(row.price)}</span><br />
                        Ext: <span className="fw-bold">{Globals.formatPrice(row.quantity * row.price)}</span>
                    </>;
                }
            }
        ];

        if (!this.state.info?.isBlocked) {
            columns.push({
                name: 'delete',
                title: '',
                valueFunc: row => <div className={'text-end ' + (this.state.showDeleteItems ? 'd-block' : 'd-none d-md-block')}><button type="button" className="btn btn-danger btn-lg d-inline-block" onClick={() => this.deleteItem(row)}><Icon icon="faTrash" /></button></div>
            });
        }

        return <div className="container-fluid">
            <Tick onTick={this.tick} interval={1000} />
            <OrderTypeModal show={this.state.showOrderTypeModal} onHide={() => this.setState({ showOrderTypeModal: false })} onSelect={t => this.sign(null, t)} />
            <form onSubmit={this.sign}>
                {!this.state.info?.isBlocked ? <div className="app-actions app-actions-form">
                    <button type="button" className="btn btn-primary btn-lg" onClick={() => this.setState({ showItemCatalog: true })}><Icon icon="faCartPlus" /></button>
                </div> : null}
                {this.state.visit ? <Card title="Visit">
                    <FormLabel value={this.state.user.user3.hasPreSale && this.state.user.user3.hasDirectSale ? <L>Order</L> : this.state.user.user3.hasPreSale ? <L>Pre-Sale</L> : <L>Direct Sale</L>} label="Order Type" />
                    <FormLabel value={this.state.visit.customerName} label="Customer" />
                    <FormLabel value={this.state.visit.customerShippingName} label="Shipping" />
                </Card> : null}
                <Card title="Items">
                    <GestureElement onSwipeRight={() => this.setState({ showDeleteItems: true })}>
                        <DataTable columns={columns} data={this.state.items} />
                        <div className="text-end fs-5">
                            <div><L>Units</L>: <span className="fw-bolder">{(() => {
                                var counter = 0;
                                this.state.items.forEach(a => counter += a.quantity);
                                return counter;
                            })()}</span></div>
                        </div>
                        <div className="text-end fs-5">
                            <div><L>Sub-Total</L>: <span className="fw-bolder">{Globals.formatPrice(( () => {
                                var total = 0;
                                this.state.items.forEach(a => total += a.quantity * a.price);
                                return total;
                            } )())}</span></div>
                        </div>
                        {!this.state.info?.isBlocked ? <div className="my-3">
                            <button type="button" className="btn btn-primary btn-lg" onClick={() => this.setState({ showItemCatalog: true })}><Icon icon="faCartPlus" /> <L>Add Item</L></button>
                        </div> : null}
                    </GestureElement>
                </Card>
                <Card title="Order">
                    <FormInput disabled={this.state.info?.isBlocked} model={this.state.info} name="deliver_on" label="Deliver On" type="date" required onChange={this.onChange} />
                    <FormInput disabled={this.state.info?.isBlocked} model={this.state.info} name="ord_type" label="Order Type" type="select" options={this.props.pre ? [
                        { id: '1', name: 'Regular' },
                        { id: '2', name: 'Credit' },
                        { id: '3', name: 'Quote' },
                        { id: '4', name: 'Order to Confirm' }
                    ] : [
                        { id: 'DI', name: 'Invoice' },
                        { id: 'DM', name: 'Debit' },
                        { id: 'CM', name: 'Credit' }
                    ]} required onChange={this.onChange} />
                    <FormInput disabled={this.state.info?.isBlocked} model={this.state.info} name="message" label="Message" type="textarea" onChange={this.onChange} />
                </Card>
                <div className="my-3 d-flex gap-1 app-form-actions">
                    <div>
                        <button type="button" className="btn btn-secondary btn-lg" onClick={this.goBack}><Icon icon="faArrowLeft" /> <L>Back</L></button>
                    </div>
                    <div className="flex-fill text-end">
                        <button type="submit" className="btn btn-primary btn-lg" disabled={!this.state.visit || this.state.saving || (this.state.info?.isSigned && !this.state.overrideSign) || this.state.info?.isBlocked}><Icon icon="faSignature" /> <L>Sign</L></button>
                    </div>
                </div>
            </form>
        </div>;
    }
}

export default withParamsAndNavigate(VisitOrderEdit);