import * as React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as fa from '@fortawesome/free-solid-svg-icons'
import { v4 as uuidv4 } from 'uuid';
import BaseCom from "./BaseCom";
import DatePicker from "react-datepicker";
import InputMask from 'react-input-mask';
import { qreq } from "../shared/qrequest";
import Alert from "./Alert";
import Globals from "../shared/Globals";
import 'react-tooltip/dist/react-tooltip.css';
import "react-datepicker/dist/react-datepicker.css";
import Icon from "./Icon";
import { getLang } from "../shared/GLang";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { Modal } from "react-bootstrap";
import './FormInput.css';

export default class FormInput extends BaseCom {

    constructor(props) {
        super(props);
        this.state = {
            model: props.model,
            value: '',
            boolValue: false,
            dateValue: new Date(),
            options: [],
            subModel: {},
            searchList: null,
            selectedList: [],
            dateAllowTime: props.dateAllowTime ?? false,
            preInput: props.preInput,
            disabled: props.disabled,
            min: props.min ?? 0,
            max: props.max ?? 100,
            step: props.step ?? 1
        };
        this.id = uuidv4();
        this.label = props.label;
        this.preAddon = props.preAddon;
        this.postpend = props.postpend;
        this.setValue = this.setValue.bind(this);
        this.labelAsPlaceholder = props.labelAsPlaceholder ? props.labelAsPlaceholder : false;
        if (this.state.model[this.props.name]) {
            this.state.value = this.state.model[this.props.name];
            if (typeof this.state.model[this.props.name] == 'boolean') {
                this.state.boolValue = this.state.model[this.props.name];
            }
        }
        if (props.type)
            this.type = props.type;
        else
            this.type = 'text';

        this.state.options = props.options;
        this.useObjectValue = props.useObjectValue ?? false;

        if (this.props.type === 'date' || this.props.type === 'dateselect') {
            var c = -1;
            var dv = this.state.value;
            if (!dv)
                dv = Globals.toISODateString(new Date());
            else if (dv.indexOf && (c = dv.indexOf(' ')) >= 0) {
                dv = dv.substring(0, c);
            }
            var d = new Date(Date.parse(dv));
            this.state.dateValue = d;
            this.state.subModel.YYYY = String(d.getFullYear());
            this.state.subModel.MM = String(d.getMonth() + 1).padStart(2, '0');
            this.state.subModel.DD = String(d.getDate()).padStart(2, '0');


        }
        else if (this.type === 'multisearch') {
            if (props.model[props.name])
                this.state.selectedList = props.model[props.name];
        }
        else if (this.type === 'multiselect' && Array.isArray(this.state.value)) {
            var bringToTop = [];
            this.state.value.forEach(i => {
                this.state.options.forEach((j) => {
                    if (this.useObjectValue) {
                        if (i.id === j.id) {
                            j.selected = true;
                            bringToTop.push(j);
                        }
                    }
                    else {
                        if (i === this.props.keyName ? j[this.props.keyName] : j.id) {
                            j.selected = true;
                            bringToTop.push(j);
                        }
                    }
                });
            });
            bringToTop.forEach(i => {
                this.state.options.splice(this.state.options.indexOf(i), 1);
                this.state.options.unshift(i);
            });
        }
        else if (this.type === 'range') {
            if (!this.state.model[this.props.name] && this.state.model[this.props.name] !== 0)
                this.state.value = null;
            else if (this.postpend) {
                var s = String(this.state.model[this.props.name]);
                if (s.endsWith(this.postpend))
                    this.state.value = s.substring(0, s.length - this.postpend.length);
            }
        }
        this.searchUrl = props.searchUrl;
        this.required = props.required ? props.required : false;
        this.optionsName = props.optionsName ?? 'name';
        this.onChange = this.onChange.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.setSelected = this.setSelected.bind(this);
        this.onDateClick = this.onDateClick.bind(this);
        this.propOnChange = props.onChange;
        this.autoComplete = props.autoComplete;
        this.multi = props.multi ?? false;
        this.addUrl = props.addUrl;
        this.months = [];
        for (var n = 1; n <= 12; n++) {
            var s = String(n);
            if (s.length < 2)
                s = '0' + s;
            this.months.push({ id: s, name: s });
        }
        this.days = [];
        for (var n = 1; n <= 31; n++) {
            var s = String(n);
            if (s.length < 2)
                s = '0' + s;
            this.days.push({ id: s, name: s });
        }
        var nowYear = Number(new Date().getUTCFullYear());
        this.years = [];
        for (var n = nowYear; n > nowYear - 150; n--) {
            var s = String(n);
            this.years.push({ id: s, name: s });
        }
        this.loadSearchDefault = this.loadSearchDefault.bind(this);
        if (this.props.type === 'search')
            this.loadSearchDefault();
        this.onFileSelect = this.onFileSelect.bind(this);
        this.toggle = this.toggle.bind(this);
        this.onPartialChange = this.onPartialChange.bind(this);
        this.onClick = this.onClick.bind(this);
        this.processChange = this.processChange.bind(this);
    }

    componentDidMount() {
        document.addEventListener('click', this.onClick);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.onClick);
    }

    onClick() {
        //this.setState({ showSelectList: false });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.model !== this.props.model) {
            this.setState({ model: this.props.model, value: this.props.model[this.props.name] ?? '', searchList: null });
            if (this.props.type === 'date' && this.props.model[this.props.name]) {
                this.setState({ dateValue: new Date(this.props.model[this.props.name]) })
            }
            this.processChange(this.props.model[this.props.name]);
        }
        else if (prevProps.model[this.props.name] !== this.props.model[this.props.name]) {
            this.setState({ value: this.props.model[this.props.name] });
            if (this.props.type === 'date' && this.props.model[this.props.name]) {
                this.setState({ dateValue: new Date(this.props.model[this.props.name]) })
            }
            this.processChange(this.props.model[this.props.name]);
        }
        if (prevState.value !== this.state.value) {
            if (this.props.type === 'search') {
                this.loadSearchDefault();
            }
            this.processChange(this.state.value);
        }

        if (prevProps.initValue !== this.props.initValue)
            this.setState({ value: this.props.initValue });
        if (prevProps.disabled !== this.props.disabled)
            this.setState({ disabled: this.props.disabled });
        if (prevProps.options !== this.props.options) {
            if (Array.isArray(this.state.value)) {
                this.state.value.forEach((i) => {
                    this.props.options.forEach((j) => {
                        if (this.useObjectValue) {
                            if (i.id === j.id)
                                j.selected = true;
                        }
                        else {
                            if (i === this.props.keyName ? j[this.props.keyName] : j.id)
                                j.selected = true;
                        }
                    });
                });
            }
            this.setState({ options: this.props.options });
        }

        if (this.state.selectedList !== this.props.model[this.props.name]) {
            if (this.type === 'multisearch') {
                if (this.props.model[this.props.name])
                    this.state.selectedList = this.props.model[this.props.name];
            }
        }


        if (this.state.options && !this.props.loadValueOnce) {
            if (this.type === 'multiselect' && Array.isArray(this.state.value)) {
                this.state.value.forEach((i) => {
                    this.state.options.forEach((j) => {
                        if (this.useObjectValue) {
                            if (i.id === j.id)
                                j.selected = true;
                        }
                        else {
                            if (i === this.props.keyName ? j[this.props.keyName] : j.id)
                                j.selected = true;
                        }
                    });
                });
            }
        }

        if (prevProps.onChange !== this.props.onChange)
            this.propOnChange = this.props.onChange;


    }

    loadSearchDefault() {
        if (typeof (this.state.value) === 'number') {
            qreq.post(this.props.searchUrl, { id: this.state.value }, j => {
                if (j.item) {
                    this.state.model[this.props.name] = j.item.id;
                    this.setState({ value: j.item.id, selectedItem: j.item });
                }
            });
        }
    }

    processChange(val) {
        if (this.props.type === 'select-search') {
            if (val) {
                var obj = this.state.options.find(a => a.id === val);
                if (obj)
                    this.setState({ displayValue: obj.name });
            }
        }
    }

    onPartialChange(e) {
        if (this.props.type === 'date') {
            this.setState({ value: e?.target?.value, dateValue: new Date(e?.target?.value) });
        }
    }

    onChange(e) {
        var val = null;
        if (e.target)
            val = e.target.value;
        if (this.props.type === 'dateselect') {
            if (e) {
                var d = new Date(Date.parse(e.YYYY + '-' + e.MM + '-' + e.DD));
                this.state.model[this.props.name] = Globals.toISODateString(d);
                this.setState({ subModel: e, value: Globals.toMDYDateString(d), dateValue: d });
                if (this.props.onChange)
                    this.props.onChange(null);
            }
            else {
                this.state.model[this.props.name] = null;
                this.setState({ value: '', dateValue: null, subModel: {} });
                if (this.props.onChange)
                    this.props.onChange(null);
            }
            return;
        }
        else if (this.props.type === 'multisearch') {
            this.setSelected(e, true);
            return;
        }
        else if (this.props.type === 'search') {

            this.setState({ searchList: null, searchValue: e.target.value });
            if (e) {
                qreq.post(this.props.searchUrl, { search: e.target.value }, j => {
                    if (!j.errorCode)
                        this.setState({ searchList: j.list });
                    else
                        this.setState({ searchList: false });
                });
            }
            return;
        }
        else if (this.props.type === 'daterange') {
            this.state.model[this.props.name] = e;
            this.setState({ value: e });
            if (this.propOnChange)
                this.propOnChange(e);
            return;
        }
        else if (this.props.type === 'date') {
            if (e) {
                if (e.target) {
                    this.state.model[this.props.name] = Globals.toISODateString(e.target.value);
                    this.setState({ value: Globals.toMDYDateString(e.target.value), dateValue: new Date(e.target.value) });
                }
                else {
                    if (!this.state.dateAllowTime) {
                        e.setHours(0);
                        e.setMinutes(0);
                        e.setSeconds(0);
                    }
                    this.state.model[this.props.name] = Globals.toISODateString(e);
                    this.setState({ value: Globals.toMDYDateString(e), dateValue: e });
                }
                if (this.propOnChange)
                    this.propOnChange(e);
            }
            else {
                this.state.model[this.props.name] = null;
                this.setState({ value: '', dateValue: null });
                if (this.propOnChange)
                    this.propOnChange(null);
            }
            return;
        }
        else if (this.type === 'switch') {
            this.set('boolValue', e.target.checked ? true : false);
            this.set('value', e.target.checked ? 'true' : 'false');
            this.state.model[this.props.name] = e.target.checked;
            if (this.propOnChange)
                this.propOnChange(e.target.checked);
        }
        else if (this.type === 'range') {
            this.set('value', e);
            if (this.postpend)
                this.state.model[this.props.name] = String(e) + this.postpend;
            else
                this.state.model[this.props.name] = e;
            if (this.propOnChange)
                this.propOnChange(e);
            return;
        }
        else if (this.props.type === 'select-search') {
            val = e.id;
            this.set('value', val);
            this.state.model[this.props.name] = val;
            if (this.propOnChange)
                this.propOnChange(val);
            this.setState({ displayValue: e.name, showSelectList: false });
        }
        else {
            if (this.props.transformUpperCase && val && val.toUpperCase)
                val = val.toUpperCase();
            this.set('value', val);
            this.state.model[this.props.name] = val;
            if (this.propOnChange)
                this.propOnChange(val);
        }
        this.setState({ value: val });

    }

    onFocus(e) {
        if (e.target.value === 0 || e.target.value === '0') {
            this.setValue('');
            e.target.value = '';
        }
    }

    setValue(v) {
        this.setState({ value: v });
        this.state.model[this.props.name] = v;
        if (this.propOnChange)
            this.propOnChange(v);
    }

    onSelect(obj) {
        if (this.type === 'search') {
            if (!this.multi) {
                if (obj)
                    this.set('value', obj.id);
                else
                    this.set('value', null);
                if (obj) {
                    if (this.useObjectValue)
                        this.state.model[this.props.name] = obj;
                    else
                        this.state.model[this.props.name] = obj.id;
                }
                else
                    this.state.model[this.props.name] = null;
                this.setState({ model: this.state.model });
            }
            this.setState({ searchList: null, subModel: this.state.subModel, selectedItem: obj });
            if (this.props.onChange)
                this.props.onChange(obj);
            if (this.props.onSelect)
                this.props.onSelect(obj, this.props.name);
        }
    }

    setSelected(obj, sel) {
        if (sel)
            this.state.selectedList.push(obj);
        else {
            var idx = this.state.selectedList.indexOf(obj);
            if (idx >= 0)
                this.state.selectedList.splice(this.state.selectedList.indexOf(obj), 1);
        }
        this.state.model[this.props.name] = this.state.selectedList;
        this.setState({ selectedList: this.state.selectedList, model: this.state.model });
        if (this.props.onChange)
            this.props.onChange(obj);
    }

    toggle(opt) {

        if (this.type === 'range') {
            if (opt.target.checked) {
                this.state.model[this.props.name] = null;
                this.setState({ value: null });
                if (this.propOnChange)
                    this.propOnChange(null);
            }
            else {
                if (this.postpend)
                    this.state.model[this.props.name] = String(0) + this.postpend;
                else
                    this.state.model[this.props.name] = 0;
                this.setState({ value: 0 });
                if (this.propOnChange)
                    this.propOnChange(0);
            }
        }
        else {
            opt.selected = !opt.selected;
            var selIds = [];
            var selObjs = [];
            var options = [...this.state.options];
            options.forEach(i => {
                if (i.selected) {
                    selIds.push(i.id);
                    selObjs.push(i);
                }
            });
            if (this.props.useObjectValue)
                this.state.model[this.props.name] = selObjs;
            else
                this.state.model[this.props.name] = selIds;
            this.setState({ options: options });
            if (this.props.onChange)
                this.props.onChange(0);
        }
    }

    onAdd(obj) {
        var item = {};
        item.name = this.state.searchValue;
        if (this.props.transformUpperCase)
            item.name = item.name.toUpperCase();
        qreq.post(this.props.addUrl, item, j => {
            if (j.errorCode === 0) {
                this.onSelect(j.item);
            }
            else
                this.alert(j.errorMessage);
        }, this.unkownErrorCallback);
    }

    onFileSelect(e) {
        if (this.props.onFileSelect)
            this.props.onFileSelect(e);
    }

    onDateClick(e) {
        if (e?.target) {
            var dt = null;
            var n = 0;
            var ele = e.target;
            while (ele && ele.tagName && ele.tagName.toUpperCase() !== 'TD' && n < 20) {
                ele = ele.parentElement;
                n++;
            }
            if (!ele || !ele.tagName || ele.tagName.toUpperCase() !== 'TD')
                return;
            var day = ele.querySelector('.fc-daygrid-day-number');
            if (day) {
                dt = day.getAttribute('aria-label');
            }
            this.onChange(new Date(dt));
            this.setState({ showDatePickerModal: false });
        }
    }

    reduceList(list, search) {
        if (!search || search === '')
            return list;
        var searchList = [];
        list.forEach(i => {
            if (i.name && i.name.toLowerCase().indexOf(search.toLowerCase()) >= 0)
                searchList.push(i);
        });
        return searchList;
    }

    render() {
        if (this.props.type === 'switch') {
            return (<div className="form-group mb-3"><div className="form-check form-switch">
                <input className="form-check-input" type="checkbox" id={this.id} checked={this.state.boolValue} onChange={this.onChange} disabled={this.props.disabled} />
                <label className="form-check-label" htmlFor={this.id}>{this.props.label}</label>
            </div></div>);
        }

        var groupedOptions = null;
        if (this.props.type === 'select' && this.props.groupBy) {
            groupedOptions = [];
            this.state.options.forEach(i => {
                var groupName = i[this.props.groupBy];
                var find = groupedOptions.find(a => a.name === groupName);
                if (!find) {
                    find = { name: groupName, list: [] };
                    groupedOptions.push(find);
                }
                find.list.push(i);
            });
        }

        return (<>
            {this.props.labelAsPlaceholder ? '' : (this.props.label && <label className="form-label">{this.props.required ? '* ' : ''}{getLang(this.props.label)} {this.props.postLabel && <span>{this.props.postLabel}</span>}</label>)}
            <div className={this.props.type === 'multiselect' || this.props.type === 'dateselect' ? 'mb-3' : 'input-group ' + (this.props.inputGroupClassName ? this.props.inputGroupClassName : 'mb-3')} style={this.props.inputGroupStyle}>
                {this.state.preInput ? <span className="input-group-text">{this.state.preInput}</span> : ''}
                {this.type === 'multisearch' ? <>
                    <FormInput model={this.state.subModel} name="selected" label="" labelAsPlaceholder={true} type="search" onChange={this.onChange} searchUrl={this.searchUrl} multi={true} />
                    <ul className="list-group">
                        {this.state.selectedList.map(obj => <li key={obj.id} className="list-group-item">{obj.name} <span className="badge bg-danger pointer c-hoverable" onClick={e => this.setSelected(obj, false)}><FontAwesomeIcon icon={fa.faTrash} /> <span className="c-visible-onhover">remove</span></span></li>)}
                    </ul>
                </> :
                    this.type === 'multiselect' ? (<ul className="list-group">
                        {this.state.options.map((opt) => (<li key={opt.id} className={'list-group-item' + (opt.selected ? ' list-group-item-success' : '')} onClick={(e) => this.toggle(opt)}><FontAwesomeIcon icon={opt.selected ? fa.faCheckSquare : fa.faSquare} /> {opt[this.optionsName]}</li>))}
                    </ul>)
                        : (<>
                            {this.preAddon ? (<div className="input-group-text">{this.preAddon}</div>) : ''}

                            {(this.type === 'select' ? (
                                <select className={'form-control form-control-lg' + (this.props.inputClassName ? ' ' + this.props.inputClassName : '')} value={this.state.value} onChange={this.onChange} required={this.required} disabled={this.props.disabled}>
                                    {!this.props.disableSelectDefault ? <option value="">{this.props.selectDefault ? this.props.selectDefault : 'Select ' + this.label}</option> : ''}
                                    {groupedOptions ? groupedOptions.map(g => <optgroup key={g.name} label={g.name}>
                                        {g.list.map(opt => <option key={opt.id} value={this.props.keyName ? opt[this.props.keyName] : opt.id}>{opt[this.optionsName]}</option>)}
                                    </optgroup>) : this.state.options.map((opt) => (<option key={opt.id} value={this.props.keyName ? opt[this.props.keyName] : opt.id}>{opt[this.optionsName]}</option>))}
                                </select>
                            ) : this.props.type === 'select-search' ? <>
                                {this.state.showSelectList ? <div className="FormInput-select-list" onClick={e => e.stopPropagation()}>
                                    <div className="input-group">
                                        <input type="text" value={this.state.searchValue} className="form-control form-control-lg" placeholder="Search..." onChange={e => this.setState({ searchValue: e.target.value })} />
                                        {false ?? <div role="button" className="input-group-text" onClick={() => this.setState({ searchValue: null })}><Icon icon="faTimes" /></div>}
                                    </div>
                                    <div className="list-group flex-fill">
                                        {this.reduceList(this.state.options, this.state.searchValue)?.map(i => <div key={i.id} role="button" className={'list-group-item' + (i.id === Number(this.state.value) ? ' active' : '')} onClick={() => this.onChange(i)}>
                                            {i.name}
                                        </div>)}
                                    </div>
                                    <div className="d-grid FormInput-select-list-buttons">
                                        <button type="button" className="btn btn-secondary btn-lg" onClick={() => this.setState({ showSelectList: false })}>Cancel</button>
                                    </div>
                                </div> : null}
                                <input type="text" placeholder={this.props.placeholder ? this.props.placeholder : 'Select ' + this.props.label} className={'form-control form-control-lg select-search' + (this.props.inputClassName ? ' ' + this.props.inputClassName : '')} value={this.state.displayValue} onChange={this.onChange} required={this.required} disabled={this.props.disabled} onClick={e => { e.stopPropagation(); this.setState({ showSelectList: true }); e.target.blur(); }} onFocus={e => { e.stopPropagation(); this.setState({ showSelectList: true }); e.target.blur(); }} />

                            </> :
                                this.type === 'search' ? <>{this.state.selectedItem && this.state.selectedItem.id ? <ul className="list-group w-100"><li className="list-group-item" onClick={() => this.onSelect(null)} style={{ cursor: 'pointer' }}>{this.state.selectedItem.name} <small>click to change</small></li></ul>
                                    : <>
                                        <input type="text" value={this.state._searchValue} placeholder={this.props.labelAsPlaceholder ? this.props.label : this.props.placeholder ?? 'Search keywords...'} onChange={this.onChange} className={'form-control form-control-lg' + (this.props.inputClassName ? ' ' + this.props.inputClassName : '')} disabled={this.props.disabled} />
                                        {!this.state.searchValue || this.state.searchList === null ? '' :
                                            this.state.searchList === false ? <Alert message="Unknown error." type="danger" className="w-100" /> :
                                                <>
                                                    <div className="d-block w-100">
                                                        <ul className="list-group" style={{ zIndex: '999', cursor: 'pointer' }}>
                                                            {this.state.searchList.map(obj => <li key={obj.id} className="list-group-item pointer" onClick={() => this.onSelect(obj)}>{obj.name}</li>)}
                                                            {this.props.addUrl && this.state.searchList && !this.state.searchList.find(a => a.name && this.state.searchValue && a.name.trim().toLowerCase() === this.state.searchValue.trim().toLowerCase()) ? <li className="list-group-item pointer" onClick={() => this.onAdd(this.state.subModel)}><i>Add '{this.props.transformUpperCase ? this.state.searchValue.toUpperCase() : this.state.searchValue}'</i></li> : ''}
                                                            {this.props.onAdd && this.state.searchList && !this.state.searchList.find(a => a.name && this.state.searchValue && a.name.trim().toLowerCase() === this.state.searchValue.trim().toLowerCase()) ? <li className="list-group-item pointer" onClick={() => this.props.onAdd(this.props.transformUpperCase ? this.state.searchValue.toUpperCase() : this.state.searchValue)}><i>Add '{this.props.transformUpperCase ? this.state.searchValue.toUpperCase() : this.state.searchValue}'</i></li> : ''}
                                                        </ul>
                                                    </div>
                                                    {this.state.searchList.length === 0 && !this.props.addUrl && !this.props.onAdd ? <Alert message="No items found." className="w-100" /> : ''}</>
                                        }
                                    </>}
                                </> :
                                    (this.type === 'upload' || this.type === 'image-upload') ? <><div className="d-none"><input type="file" id={'c-upload-' + this.id} onChange={this.onFileSelect} /></div><div className="btn-group"><button type="button" className="btn btn-primary" onClick={() => document.getElementById('c-upload-' + this.id).click()} accept={this.type === 'image-upload' ? 'image/*' : null}><FontAwesomeIcon icon={fa.faUpload} /> Select {this.type === 'image-upload' ? 'Image' : 'File'}</button></div></> :
                                        this.type === 'range' ? <>{this.state.value !== null ? <><div className="d-block w-100"><input type="range" onChange={e => this.onChange(e.target.value)} value={this.state.value} min={this.state.min} max={this.state.max} step={this.state.step} /></div><div className="d-block w-100"><input type="number" onChange={e => this.onChange(e)} className="form-control" value={this.state.value} /></div></> : ''}<div className="d-block"><input type="checkbox" checked={this.state.value === null} onChange={this.toggle} /> No value.</div></> :
                                            this.type === 'dateselect' ? <><div className="d-flex">
                                                <div className="mx-1">
                                                    <div className="input-group">
                                                        <span className="input-group-text">M</span>
                                                        <select disabled={this.props.disabled} defaultValue={this.state.subModel.MM} className="form-control" onChange={e => {
                                                            this.onChange({ ...this.state.subModel, MM: e.target.value });
                                                        }}>
                                                            <option value="">MM</option>
                                                            {this.months.map(i => <option key={i.id} value={i.id}>{i.name}</option>)}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="mx-1">
                                                    <div className="input-group">
                                                        <span className="input-group-text">D</span>
                                                        <select disabled={this.props.disabled} defaultValue={this.state.subModel.DD} className="form-control" onChange={e => {
                                                            this.onChange({ ...this.state.subModel, DD: e.target.value });
                                                        }}>
                                                            <option value="">DD</option>
                                                            {this.days.map(i => <option key={i.id} value={i.id}>{i.name}</option>)}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="mx-1">
                                                    <div className="input-group">
                                                        <span className="input-group-text">Y</span>
                                                        <select disabled={this.props.disabled} defaultValue={this.state.subModel.YYYY} className="form-control" onChange={e => {
                                                            this.onChange({ ...this.state.subModel, YYYY: e.target.value });
                                                        }}>
                                                            <option value="">YYYY</option>
                                                            {this.years.map(i => <option key={i.id} value={i.id}>{i.name}</option>)}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div></> :
                                                this.type === 'date' ? (

                                                    <>
                                                        <Modal show={this.state.showDatePickerModal} onHide={() => this.setState({ showDatePickerModal: false })} centered>
                                                            <Modal.Body onClick={this.onDateClick}>
                                                                <FullCalendar plugins={[dayGridPlugin]} initialView="dayGridMonth" initialDate={this.state.dateValue} events={[
                                                                    {
                                                                        id: 1,
                                                                        title: 'SEL',
                                                                        start: this.state.dateValue,
                                                                        allDay: true
                                                                    }
                                                                ]}
                                                                    headerToolbar={{
                                                                        left: null,
                                                                        center: "title"
                                                                    }} height={550} />
                                                            </Modal.Body>
                                                        </Modal>
                                                        <input readOnly onClick={() => this.setState({ showDatePickerModal: true })} type="text" className="form-control" value={Globals.toMDYLocalDateString(this.state.dateValue)} required={this.props.required} placeholder={'MM/dd/yyyy'} disabled={this.props.disabled} autoComplete={false} autoFocus={this.props.autoFocus} />
                                                    </>

                                                    /*    <DatePicker showTimeInput={this.state.dateAllowTime} dateFormat={this.state.dateAllowTime ? 'MM/dd/yyyy hh:mm aa' : 'MM/dd/yyyy'} selected={this.state.dateValue} onChange={this.onChange} customInput={<InputMask className="form-control" mask={this.state.dateAllowTime ? '99/99/9999 99:99 aa' : '99/99/9999'} />} formatChars={{ 'a': '[APM]' }} disabled={this.state.disabled} />
                                                    
                                                  */

                                                ) :
                                                    (
                                                        (this.type === 'textarea' ? <textarea className="form-control" value={this.state.value} onChange={this.onChange} onKeyUp={this.props.onKeyUp} onMouseUp={this.props.onMouseUp} required={this.props.required} placeholder={this.props.labelAsPlaceholder ? this.props.label : this.props.placeholder} rows={this.props.rows ?? 4} ref={this.props.inputRef} style={this.props.inputStyle} disabled={this.props.disabled} maxLength={this.props.maxLength} autoFocus={this.props.autoFocus}></textarea>
                                                            : <input type={this.props.type} className="form-control" value={this.state.value} step={this.props.step} onFocus={this.onFocus} onChange={this.onChange} required={this.props.required} placeholder={this.labelAsPlaceholder ? this.label : this.props.placeholder} disabled={this.state.disabled} autoComplete={this.props.autoComplete} autoFocus={this.props.autoFocus} />)))}
                            {this.props.postAddon ? <div className="input-group-text">{this.props.postAddon}</div> : ''}
                            {this.props.type === 'number' && !this.props.noHelpers ? <div className="d-flex gap-1 d-block w-100 mt-1">
                                <button type="button" className="btn btn-secondary btn-lg flex-fill" onClick={() => this.setValue(this.state.value - 10)}><Icon icon="faBackward" /></button>
                                <button type="button" className="btn btn-secondary btn-lg flex-fill" onClick={() => this.setValue(this.state.value - 1)}><Icon icon="faCaretLeft" /></button>
                                <button type="button" className="btn btn-secondary btn-lg flex-fill" onClick={() => this.setValue(this.state.value + 1)}><Icon icon="faCaretRight" /></button>
                                <button type="button" className="btn btn-secondary btn-lg flex-fill" onClick={() => { if (this.state.value === 1) this.setValue(this.state.value + 9); else this.setValue(this.state.value + 10); }}><Icon icon="faForward" /></button>
                            </div> : ''}
                        </>)}
            </div></>);
    }
}