import Globals from "../shared/Globals";
import { qreq } from "../shared/qrequest";
import BaseCom from "./BaseCom";
import Card from "./Card";
import FormInput from "./FormInput";
import FormLabel from "./FormLabel";
import Icon from "./Icon";
import L from "./Lang";
import { NavMenuGlobal } from "./NavMenu";
import Tick from "./Tick";
import { withParamsAndNavigate } from "./WithParamsAndNavigate";

class StatementView extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            item: {
                payment: {
                    discount: 0,
                    collected: 0
                },
                header: {},
                discount: {}
            },
            bankList: []
        };
        this.calcBalance = this.calcBalance.bind(this);
        this.submit = this.submit.bind(this);
        this.onCheckNumChanged = this.onCheckNumChanged.bind(this);
    }

    componentDidMount() {
        if (this.props.params.visitID)
            NavMenuGlobal.setPath('Visits,/console/visit;Order,/console/visit/' + this.props.params.visitID + '/order;A/R,/console/visit/' + this.props.params.visitID + '/statement/' + this.props.params.custID + ';View');
        else
            NavMenuGlobal.setPath('A/R,/console/statement;Customer,/console/statement/' + this.props.params.custID + ';View');
        qreq.get('/api/ar/item?id=' + this.props.params.id, j => {
            if (j.item) {
                j.item.header.check_num = null;
                this.setState({ item: j.item });
                qreq.get('/api/ar/bank/list', j => {
                    if(j.list)
                        this.setState({ bankList: j.list });
                });
                
                qreq.get('/api/ar/cash/item?arID=' + j.item.id, j => {
                    if(j.item) {
                        this.setState({ item: {...this.state.item, ...j.item} });
                        this.calcBalance();
                    }
                });
            }
            else
                this.alert(j.errorMessage);
        }, this.unkownErrorCallback);
    }

    componentWillUnmount() {
        NavMenuGlobal.clearTitle();
    }

    calcBalance() {
        this.setState({ balance: Math.round( (this.state.item.balance - this.state.item.payment.discount - this.state.item.payment.collected) * 100)/100 });
    }

    onCheckNumChanged(v) {
        if(v) {
            v = v.replace(/[^0-9]+/g, '');
            this.setState({ item: {...this.state.item, check_num: v }});
        }
    }

    submit(e) {
        if(e) e.preventDefault();
        var item = {...this.state.item};
        if(!item.payment.discount)
            item.payment.discount = 0;
        else
            item.payment.discount = Number(item.payment.discount);
        if(!item.payment.collected)
            item.payment.collected = 0;
        else
            item.payment.collected = Number(item.payment.collected);
        item.payment.disc_amt = item.payment.discount;
        item.payment.trans_amt = item.payment.collected;
        item.lrdt = Globals.toISOLocalDateString(new Date());
        qreq.post('/api/ar/save', item, j => {
            if(j.errorCode === 0) {
                this.success('Saved!');
                this.goBack();
            } else {
                this.alert(j.errorMessage);
            }
        }, this.unkownErrorCallback);
    }

    render() {
        return <div className="container-fluid">
            <Tick onTick={this.calcBalance} />
            <Card title="Statement">
                <div className="row text-center">
                    <div className="col">
                        <FormLabel value={this.state.item.trans_num} label="Transaction" />
                    </div>
                    <div className="col">
                        <FormLabel value={this.state.item.transactionType} label="Type" />
                    </div>
                    <div className="col">
                        <FormLabel value={Globals.toMDYLocalDateString(this.state.item.trans_date)} label="Date" />
                    </div>
                </div>
                <div className="row text-center">
                    <div className="col">
                        <FormLabel value={Globals.formatPrice(this.state.item.trans_amt)} label="Amount" />
                    </div>
                    <div className="col">
                        <FormLabel value={Globals.formatPrice(this.state.item.possible_disc)} label="Discount" />
                    </div>
                    <div className="col">
                        <FormLabel value={Globals.formatPrice(this.state.item.balance)} label="Balance" />
                    </div>
                </div>
            </Card>
            <form onSubmit={this.submit}>
                <Card title="Header">
                    <FormInput model={this.state.item.header} name="bank_code" type="select" label="Bank Code" options={this.state.bankList} required onChange={() => this.setState({ item: {...this.state.item}})} />
                    <FormInput model={this.state.item.header} name="check_num" type="number" label="Check Number" required noHelpers onChange={() => this.setState({ item: {...this.state.item}})} />
                    <FormInput model={this.state.item.header} name="check_date" type="date" label="Check Date" required onChange={() => this.setState({ item: {...this.state.item}})} />
                </Card>
                <Card title="Payment">
                    <FormInput model={this.state.item.payment} name="discount" type="number" step="0.01" label="Discount" noHelpers placeholder="0.00" preInput="$" clearZero onChange={() => this.setState({ item: {...this.state.item}})} />
                    {this.state.item.payment.discount ? <FormInput model={this.state.item.discount} name="disc_code" type="select" label="Discount Type" options={[
                        { id: '0', name: <L>Term Discount</L> },
                        { id: '1', name: <L>Decomissioned</L> },
                        { id: '2', name: <L>Credit</L> },
                        { id: '9', name: <L>Tax Return</L> }
                    ]} required onChange={() => this.setState({ item: {...this.state.item}})} /> : null}
                    <FormInput model={this.state.item.payment} name="collected" type="number" step="0.01" label="Collected" noHelpers placeholder="0.00" preInput="$" clearZero onChange={() => this.setState({ item: {...this.state.item}})} />
                    <label className="form-label">Balance</label>
                    <div className="input-group mb-3">
                        <span className="input-group-text">$</span>
                        <input type="number" className="form-control" value={this.state.balance} disabled />
                    </div>
                </Card>
                <div className="my-3 d-flex gap-1 app-form-actions">
                    <div>
                        <button type="button" className="btn btn-secondary btn-lg" onClick={this.goBack}><Icon icon="faArrowLeft" /> <L>Back</L></button>
                    </div>
                    <div className="flex-fill text-end">
                        <button type="submit" className="btn btn-primary btn-lg"><Icon icon="faSave" /> <L>Submit</L></button>
                    </div>
                </div>
            </form>
        </div>;
    }
}

export default withParamsAndNavigate(StatementView);